<script>
import Swal from 'sweetalert2'

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
        };
    },
    page: {
    title: "Convention",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Convention",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Convention",
          active: true,
        },
        {
          text: "Une Convention",
          active: true,
        },
      ],
      convention: {},
      id: "",
      errors: [],
      loading: false,
      message: "",
      STORAGE_URL: Helper.STORAGE_URL,
    };
  },
  methods: {
    getItem() {
      fetch(Helper.route('conventions/'+this.id), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.convention = data.data
      })
    },
    rejectItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('status', -1)
      formData.append('message', this.message)
      formData.append('_method', "PUT")
      fetch(Helper.route('convention-pvs/'+this.convention.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.message = ""
          this.doesEditOpen = false
          this.convention = data.data
          Swal.fire("PV & Facture réjéter !", "PV & Facture réjéter avec succès.", "success");
        } else {
            this.errors.message = Helper.getError(data.data, "message")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    approveIt() {
      this.loading = true
      let formData = new FormData()
      formData.append('status', 1)
      formData.append('_method', "PUT")
      fetch(Helper.route('convention-pvs/'+this.convention.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.message = ""
          this.doesDeleteOpen = false
          this.convention = data.data
          Swal.fire("PV & Facture approuver !", "PV & Facture approuver avec succès.", "success");
        } else {      
            this.errors.message = Helper.getError(data.data, "message")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    initItem(action="edit") {
        if (action == "approve") {
          this.doesDeleteOpen = true
        } else if(action == "view") {
          this.doesAddOpen = true
        } else {
          this.doesEditOpen = true
        }
    },
  },
  mounted() {
    this.id = this.$route.params.id
    this.getItem()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    
    <div class="row" v-if="convention.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">À propos de la Convention</h4>
            <p class="card-title-desc mb-3">
              Présentation de la <code> Convention </code>.
            </p>

            <hr>

            <div class="row mt-4 gy-3">
              <div class="col-md-3">
                <dl>
                  <dt>Forêt</dt>
                  <dd class="mt-2">
                    <router-link :to="'/entity/forests/'+convention.forest.id">{{ convention.forest.name }}</router-link>  
                  </dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Convention</dt>
                  <dd class="mt-2 mb-3">
                    <a :href="STORAGE_URL+convention.convention" v-if="convention.convention !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                        <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                        Télécharger le Fichier
                    </a>  
                  </dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Date d'envoie</dt>
                  <dd class="mt-2 mb-3">{{ convention.date_send_convention }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>IF Statut</dt>
                  <dd class="mt-2 mb-3">
                      {{ (convention.convention_validation_step == 1 && convention.convention_status == 1) || convention.convention_validation_step == 2 ? "Approuvé" : ""  }}
                      {{ convention.convention_validation_step == 1 && convention.convention_status == 0 ? "En étude" : "" }}
                      {{ convention.convention_validation_step == 1 && convention.convention_status == -1 ? "Réjétée" : "" }}
                  </dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Direction Statut</dt>
                  <dd class="mt-2 mb-3">
                    {{ (convention.convention_validation_step == 2 && convention.convention_status == 1) ? "Approuvé" : ""  }}
                    {{ convention.convention_validation_step == 2 ? (convention.convention_status == 0 ?  "En étude" : "") : "Au niveau de IF" }}
                    {{ convention.convention_validation_step == 2 && convention.convention_status == -1 ? "Réjétée" : "" }}
                  </dd>
                </dl>
              </div>
              <div class="col-md-3" v-if="convention.convention_validation_step == 2 && convention.convention_status == 1">
                <dl>
                  <dt>Convention Finale</dt>
                  <dd class="mt-2 mb-3">
                    <a :href="STORAGE_URL+convention.final_convention" v-if="convention.final_convention !== null" target="_blank" class="btn btn-sm btn-info rounded-pill">
                        <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                        Télécharger le Fichier
                    </a>  
                  </dd>
                </dl>
              </div>
              <div class="col-md-3" v-if="convention.convention_validation_step == 2 && convention.convention_status == 1">
                <dl>
                  <dt>Date d'approbation IF</dt>
                  <dd class="mt-2 mb-3">
                      {{ convention.date_if_convention }}
                  </dd>
                </dl>
              </div>
              <div class="col-md-3" v-if="convention.convention_validation_step == 2 && convention.convention_status == 1">
                <dl>
                  <dt>Date d'approbation Finale</dt>
                  <dd class="mt-2 mb-3">
                      {{ convention.convention_date_approved }}
                  </dd>
                </dl>
              </div>
              <div class="col-md-3" v-if="convention.convention_validation_step == 2 && convention.convention_status == 1">
                <dl>
                  <dt>Direction qui a approuvé</dt>
                  <dd class="mt-2 mb-3">
                    <template v-if="convention.entity_convention != null">
                      <router-link :to="'/entity/directions/'+convention.entity_convention.id">{{ convention.entity_convention.name }}</router-link>
                    </template>
                  </dd>
                </dl>
              </div>
            </div>


            <template v-if="convention.convention_validation_step == 2 && convention.convention_status == 1">
              <div>
                <hr>
                
                <div v-if="convention.pv != null" class="row mt-4">
                  <div class="col-md-3">
                    <dl>
                      <dt>PV</dt>
                      <dd class="mt-2 mb-3">
                        <a :href="STORAGE_URL+convention.pv" v-if="convention.pv !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                            <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                            Télécharger le Fichier
                        </a>  
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Facture</dt>
                      <dd class="mt-2 mb-3">
                        <a :href="STORAGE_URL+convention.invoice" v-if="convention.invoice !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                            <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                            Télécharger le Fichier
                        </a>  
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Date d'envoie</dt>
                      <dd class="mt-2 mb-3">{{ convention.date_send_pv }}</dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>IF Statut</dt>
                      <dd class="mt-2 mb-3">
                          {{ (convention.pv_validation_step == 1 && convention.pv_status == 1) || convention.pv_validation_step == 2 ? "Approuvé" : ""  }}
                          {{ convention.pv_validation_step == 1 && convention.pv_status == 0 ? "En étude" : "" }}
                          {{ convention.pv_validation_step == 1 && convention.pv_status == -1 ? "Réjétée" : "" }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Direction Statut</dt>
                      <dd class="mt-2 mb-3">
                        {{ (convention.pv_validation_step == 2 && convention.pv_status == 1) ? "Approuvé" : ""  }}
                        {{ convention.pv_validation_step == 2 ? (convention.pv_status == 0 ?  "En étude" : "") : "Au niveau de IF" }}
                        {{ convention.pv_validation_step == 2 && convention.pv_status == -1 ? "Réjétée" : "" }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3" v-if="convention.pv_validation_step == 2 && convention.pv_status == 1">
                    <dl>
                      <dt>Date d'approbation IF</dt>
                      <dd class="mt-2 mb-3">
                          {{ convention.date_if_pv }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3" v-if="convention.pv_validation_step == 2 && convention.pv_status == 1">
                    <dl>
                      <dt>Date d'approbation Finale</dt>
                      <dd class="mt-2 mb-3">
                          {{ convention.pv_date_approved }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3" v-if="convention.pv_validation_step == 2 && convention.pv_status == 1">
                    <dl>
                      <dt>Direction qui a approuvé</dt>
                      <dd class="mt-2 mb-3">
                        <template v-if="convention.entity_pv != null">
                          <router-link :to="'/entity/directions/'+convention.entity_pv.id">{{ convention.entity_pv.name }}</router-link>
                        </template>
                      </dd>
                    </dl>
                  </div>
                </div>

                
                <div class="hstack my-3" v-if="convention.pv != null && convention.pv_validation_step == 2">
                  <div class="hstack" v-if="convention.pv_status == 0">
                    <b-button variant="success" @click="initItem('approve')" class="btn-md">
                        Approuver
                    </b-button>
                    <b-button variant="danger" @click="initItem('reject')" class="mx-2 btn-md">
                        Réjéter
                    </b-button>
                  </div>
                  <div v-if="convention.pv_status == -1">
                    <b-button variant="warning" @click="initItem('view')" class="me-2 btn-md">
                      Message de rejet
                    </b-button>
                  </div>
                  <hr class="m-0">
                </div>

                
              </div>
            </template>

          </div>
        </div>
      </div>
    </div>


    
    
    <!-- ADD MODAL -->
    <b-modal
        v-model="doesEditOpen"
        id="modal-center"
        size="md"
        title="Réjéter PV & Facture"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="rejectItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label for="">Message de rejet</label>
                <textarea  class="form-control" required v-model="message" rows="3"></textarea>
                <span class="text-danger mt-2" v-if="errors.message">{{ errors.message }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Envoyer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Approuver PV & Facture"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment approuver PV & Facture ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
          <button class="ms-3 btn btn-success" @click="approveIt()">Approuver</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>

    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        title="Message de rejet"
        title-class="font-18"
        centered
        hide-footer
        >
        <p class="mb-2 lh-lg">
            {{ convention?.pv_message }}
        </p>
    </b-modal>


  </Layout>
</template>
